<template>
  <div id="highscoreTable">
    <table>
      <thead>
        <tr>
          <th>No.</th>
          <th>Anonymous Name</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(score, index) in scoreList"
          :key="score.score"
          :class="{ currentUser: score.currentUser }"
        >
          <td>#{{ index + 1 }}</td>
          <td>
            {{ score.name }}
          </td>
          <td class="score">
            {{ score.score }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { listUserScore } from "@/graphql/customQueries";

import { useStore } from "vuex";
export default {
  setup(props) {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      scoreList: [],
      user: JSON.parse(this.$store.state.user),
    };
  },
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  watch: {
    locked(val) {
      if (!val) {
        this.user = JSON.parse(this.$store.state.user);
        this.fetchScore();
      }
    },
  },
  mounted() {
    if (!this.$store.getters.locked) this.fetchScore();
  },
  methods: {
    fetchScore() {
      let names = this.$store.state.names;
      try {
        API.graphql(graphqlOperation(listUserScore)).then((result) => {
          let res = result.data?.listUsers?.items;
          this.scoreList = res
            .map((x) => ({
              currentUser: this.user.id === x.id,
              name:
                this.user.id === x.id
                  ? this.user.Name
                  : names[Math.floor(Math.random() * names.length)],
              score: x.Score,
            }))
            .sort(function (a, b) {
              return b.score - a.score;
            });
        });
      } catch (fetchScoreError) {
        console.error({ fetchScoreError });
      }
    },
  },
};
</script>
<style scoped>
#highscoreTable {
  overflow: auto;
  height: 90%;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}
#highscoreTable thead th {
  position: sticky;
  top: 0;
  background: #eee;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}
.score {
  text-align: right;
}
.currentUser {
  background: rgb(101, 187, 228);
}
</style>
