import { Attachment } from 'mdue'
import { createStore } from 'vuex'
import json from './names.json'
const defaultScenario = "15211604-1683-438e-98ba-7d83ca5c8c43"

export default createStore({
  state: {
    user: localStorage.getItem('user') || "{}",
    currentMessage: localStorage.getItem('currentMessage') || "{}",
    attachment: localStorage.getItem('attachment') || null,
    scenario: localStorage.getItem('scneario') || defaultScenario,
    progressID: localStorage.getItem('progress') || "",
    names: json, // retrieved via randomlists.com
    selectedTitle: "Start",
    end: false,
    locked: localStorage.getItem('locked') || true
  },
  getters: {
    currentMessage(state) 
    {
      return state.currentMessage
    },
    attachment(state){
      return state.attachment
    },
    locked(state){
      if (typeof state.locked == typeof '')
        return localStorage.getItem('locked')!=='false'
      return state.locked
    },
    progress(state){
      return state.progressID
    }
  },
  mutations: {
    setUser(state, userObject){
      localStorage.setItem('user', userObject)
      state.user = userObject
    },
    nextMessage(state, nextMessage){
      localStorage.setItem('currentMessage', nextMessage)
      state.currentMessage = nextMessage
      localStorage.removeItem('attachment')
      state.attachment = null
    },
    setScenario(state, scenarioID){
      localStorage.setItem('scenario', scenarioID)
      state.scenario = scenarioID
    },
    nextAttachment(state, nextAttachment){
      localStorage.setItem('attachment', nextAttachment)
      state.attachment = nextAttachment
    },
    currentProgress(state, newProgress){
      localStorage.setItem('progress', newProgress)
      state.progressID = newProgress
    },
    selectTitle(state, title){
      state.selectedTitle = title
    },
    end(state, end){
      state.end = end
    },
    unlock(state){
      state.locked = false
      localStorage.setItem('locked', false)
    },
    /**
     * This function is used to reset the state when logging in 
     * @param {Object} state 
     */
    login(state){
      localStorage.removeItem('attachment')
      localStorage.removeItem('currentMessage')
      localStorage.removeItem('user')
      localStorage.removeItem('scenario')
      localStorage.removeItem('progress')
      localStorage.removeItem('locked')
      state.user = '{}'
      state.currentMessage = '{}'
      state.attachment = null
      state.scenario = defaultScenario
      state.progressID = ""
      state.locked=true
    }
  },
  actions: {
  },
  modules: {
  }
})
