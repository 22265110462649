/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      Content
      MetaData
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Content
        MetaData
        instantFail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocuments = /* GraphQL */ `
  query SyncDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Content
        MetaData
        instantFail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getURLS = /* GraphQL */ `
  query GetURLS($id: ID!) {
    getURLS(id: $id) {
      id
      URL
      Content
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listURLS = /* GraphQL */ `
  query ListURLS(
    $filter: ModelURLSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listURLS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        URL
        Content
        instantFail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncURLS = /* GraphQL */ `
  query SyncURLS(
    $filter: ModelURLSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncURLS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        URL
        Content
        instantFail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportHints = /* GraphQL */ `
  query GetReportHints($id: ID!) {
    getReportHints(id: $id) {
      id
      Hint
      Messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReportHints = /* GraphQL */ `
  query ListReportHints(
    $filter: ModelReportHintsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportHints = /* GraphQL */ `
  query SyncReportHints(
    $filter: ModelReportHintsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportHints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProgress = /* GraphQL */ `
  query GetProgress($id: ID!) {
    getProgress(id: $id) {
      id
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Progress
      Goal
      MessagesPlayed {
        nextToken
        startedAt
      }
      Completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      progressUserId
      progressScenarioId
    }
  }
`;
export const listProgresses = /* GraphQL */ `
  query ListProgresses(
    $filter: ModelProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProgresses = /* GraphQL */ `
  query SyncProgresses(
    $filter: ModelProgressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      nextToken
      startedAt
    }
  }
`;
export const getStatistics = /* GraphQL */ `
  query GetStatistics($id: ID!) {
    getStatistics(id: $id) {
      id
      Completed
      scenarios {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      CorrectlyAnswered
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statisticsScenariosId
      statisticsUserId
      statisticsMessagesId
    }
  }
`;
export const listStatistics = /* GraphQL */ `
  query ListStatistics(
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Completed
        CorrectlyAnswered
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statisticsScenariosId
        statisticsUserId
        statisticsMessagesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStatistics = /* GraphQL */ `
  query SyncStatistics(
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Completed
        CorrectlyAnswered
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statisticsScenariosId
        statisticsUserId
        statisticsMessagesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      Message
      Type
      Points
      Difficulty
      Phishing
      Hints {
        nextToken
        startedAt
      }
      Sender
      SenderName
      Subject
      progresss {
        nextToken
        startedAt
      }
      urlsID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getScenario = /* GraphQL */ `
  query GetScenario($id: ID!) {
    getScenario(id: $id) {
      id
      Name
      Description
      Image
      Plan
      BuisnessUnits {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listScenarios = /* GraphQL */ `
  query ListScenarios(
    $filter: ModelScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScenarios = /* GraphQL */ `
  query SyncScenarios(
    $filter: ModelScenarioFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScenarios(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAchievements = /* GraphQL */ `
  query GetAchievements($id: ID!) {
    getAchievements(id: $id) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAchievements = /* GraphQL */ `
  query SyncAchievements(
    $filter: ModelAchievementsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAchievements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBuisnessUnit = /* GraphQL */ `
  query GetBuisnessUnit($id: ID!) {
    getBuisnessUnit(id: $id) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Scenarios {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBuisnessUnits = /* GraphQL */ `
  query ListBuisnessUnits(
    $filter: ModelBuisnessUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuisnessUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBuisnessUnits = /* GraphQL */ `
  query SyncBuisnessUnits(
    $filter: ModelBuisnessUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBuisnessUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      Name
      Mail
      Score
      Achievements {
        nextToken
        startedAt
      }
      BuisnessUnit
      Phished
      CognitoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessagesReportHints = /* GraphQL */ `
  query GetMessagesReportHints($id: ID!) {
    getMessagesReportHints(id: $id) {
      id
      reportHintsID
      messagesID
      reportHints {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessagesReportHints = /* GraphQL */ `
  query ListMessagesReportHints(
    $filter: ModelMessagesReportHintsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesReportHints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportHintsID
        messagesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessagesReportHints = /* GraphQL */ `
  query SyncMessagesReportHints(
    $filter: ModelMessagesReportHintsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessagesReportHints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportHintsID
        messagesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProgressMessages = /* GraphQL */ `
  query GetProgressMessages($id: ID!) {
    getProgressMessages(id: $id) {
      id
      progressID
      messagesID
      progress {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProgressMessages = /* GraphQL */ `
  query ListProgressMessages(
    $filter: ModelProgressMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgressMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        messagesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProgressMessages = /* GraphQL */ `
  query SyncProgressMessages(
    $filter: ModelProgressMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProgressMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        progressID
        messagesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getScenarioBuisnessUnit = /* GraphQL */ `
  query GetScenarioBuisnessUnit($id: ID!) {
    getScenarioBuisnessUnit(id: $id) {
      id
      scenarioID
      buisnessUnitID
      scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buisnessUnit {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listScenarioBuisnessUnits = /* GraphQL */ `
  query ListScenarioBuisnessUnits(
    $filter: ModelScenarioBuisnessUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScenarioBuisnessUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scenarioID
        buisnessUnitID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScenarioBuisnessUnits = /* GraphQL */ `
  query SyncScenarioBuisnessUnits(
    $filter: ModelScenarioBuisnessUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScenarioBuisnessUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scenarioID
        buisnessUnitID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAchievementsUser = /* GraphQL */ `
  query GetAchievementsUser($id: ID!) {
    getAchievementsUser(id: $id) {
      id
      achievementsID
      userID
      achievements {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAchievementsUsers = /* GraphQL */ `
  query ListAchievementsUsers(
    $filter: ModelAchievementsUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievementsUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        achievementsID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAchievementsUsers = /* GraphQL */ `
  query SyncAchievementsUsers(
    $filter: ModelAchievementsUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAchievementsUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        achievementsID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
