<template>
  <span
    v-if="document!==null"
    v-html="document?.Content"
  />
  <h1 v-else>
    Currently no document is open
  </h1>
</template>
<script setup>
import {useStore} from "vuex";
const store = useStore()
</script>

<script>
export default{
    data() {
    return {
      document: null,
      metaData: {}
    };
  },
  mounted() { 
    this.emitter.on("open-attachment", attachment => {
      this.document = attachment;
      this.metaData = JSON.parse(attachment.MetaData)
    });
    this.document = JSON.parse(this.$store.getters.attachment)
    this.metaData = this.document?.MetaData?JSON.parse(this.document?.MetaData):{}
  }
};
</script>