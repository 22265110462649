<template>
  <authenticator :form-fields="formFields" initial-state="signUp">
    <!-- This is the styling section for the amplify login form-->
    <template v-slot:header>
      <div style="padding: var(--amplify-space-large); text-align: center">
        <ti-logo />
      </div>
    </template>
    <template v-slot:sign-in-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Sign in to your account
      </h3>
    </template>

    <template v-slot:sign-up-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Create a new account
      </h3>
    </template>

    <template v-slot:sign-up-footer>
      <div style="text-align: center; color: grey">
        *The password has to be atleast 16 chars long and needs to contain
        atleast one of each of the following: lowercase-letters,
        uppercase-letters, numbers, symbols
      </div>
    </template>
    <template v-slot:footer>
      <p
        style="
          width: 90vw !important;
          position: absolute;
          left: 5vw;
          font-size: 18pt;
          text-align: justify;
          padding-bottom: 10px;
        "
      >
        Welcome to <span style="color:#90bc15">Team Internet's Phishing Prevention Game</span>. If you are stranded
        here, you either fell for one of our internal phishing emails, or you've
        been invited to play this game. Either way, thanks for attending. This
        game is part of my (Thorsten Rösler) Master Thesis. Therefore I would
        appreciate it if you would take the time to play the game and answer the
        questionnaire shown on the EndScreen. If this occurs phishy to you,
        that's good, so feel free to contact either <span style="color:#90bc15">Thorsten Rösler</span> (<a
          href="mailto:thorsten.roesler@teaminternet.com"
          >thorsten.roesler@teaminternet.com</a
        >) or <span style="color:#90bc15">Andreas Lunz</span> (<a
          href="mailto:andreas.lunz@teaminternet.com?cc=thorsten.roesler@teaminternet.com"
          >andreas.lunz@teaminternet.com</a
        >) via Mail or Slack. But please keep it secret from the other
        colleagues so I can invite them too without them getting forewarned.
      </p>
      <div
        style="
          text-align: center;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
        "
      >
        <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
          © Team Internet AG, All Rights Reserved
        </p>
      </div>
    </template>

    <template v-slot:confirm-sign-up-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Enter Information:
      </h3>
    </template>

    <template v-slot:confirm-sign-up-footer>
      <div>
        Make sure to look in your spam folder. If there are any problems contact
        <a href="mailto:thorsten.roesler@teaminternet.com"
          >thorsten.roesler@teaminternet.com</a
        >
      </div>
    </template>
    <template v-slot:confirm-sign-in-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Enter Information:
      </h3>
    </template>

    <template v-slot:confirm-sign-in-footer>
      <div>Footer Information</div>
    </template>

    <template v-slot:reset-password-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Enter Information:
      </h3>
    </template>

    <template v-slot:reset-password-footer>
      <div>Footer Information</div>
    </template>

    <template v-slot:confirm-reset-password-header>
      <h3
        class="amplify-heading"
        style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
      >
        Enter Information:
      </h3>
    </template>
    <template v-slot:confirm-reset-password-footer>
      <div>Footer Information</div>
    </template>
    <!-- Main Area here the taskbar is declared-->
    <template v-if="store.state.end">
      <WindowWrapper>
        <WindowContent title="End" icon="flag">
          <EndScreen />
        </WindowContent>
        <WindowContent title="Start" icon="book">
          <StartScreen />
        </WindowContent>
        <WindowContent title="Account" icon="account">
          <AccountWindow />
        </WindowContent>
      </WindowWrapper>
    </template>
    <template v-else>
      <WindowWrapper>
        <WindowContent title="Start" icon="book">
          <StartScreen />
        </WindowContent>
        <WindowContent title="Mail" icon="email">
          <MailWindow />
        </WindowContent>
        <WindowContent title="Browser" icon="compass">
          <BrowserWindow />
        </WindowContent>
        <WindowContent title="Preview" icon="magnifier">
          <DocumentWindow />
        </WindowContent>
        <WindowContent title="Account" icon="account">
          <AccountWindow />
        </WindowContent>
      </WindowWrapper>
    </template>
    <EvaluationWindow id="evaluationWindow" />
  </authenticator>
</template>

<script setup>
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import WindowWrapper from "./components/WindowWrapper.vue";
import WindowContent from "./components/WindowContent.vue";
import MailWindow from "./components/MailWindow.vue";
import BrowserWindow from "./components/BrowserWindow.vue";
import DocumentWindow from "./components/DocumentWindow.vue";
import EvaluationWindow from "./components/EvaluationWindow.vue";
import AccountWindow from "./components/AccountWindow.vue";
import EndScreen from "./components/EndScreen.vue";
import StartScreen from "./components/StartScreen.vue";
import { API, graphqlOperation, Hub } from "aws-amplify";
import { createUser } from "./graphql/mutations";
import { listUsers } from "./graphql/queries";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { Trophy } from "mdue";
import tiLogo from "./components/TiLogo.vue";

const store = useStore();
const auth = useAuthenticator();
if (!localStorage.getItem("user")) {
  try {
    API.graphql(
      graphqlOperation(listUsers, {
        filter: { CognitoID: { eq: auth.user?.attributes?.sub } },
      })
    ).then((res) => {
      const data = res.data.listUsers.items[0];
      store.commit("setUser", JSON.stringify(data));
    });
  } catch (listUsersError) {
    console.error({ listUsersError });
  }
}
// definition of Form field Texts ans labels
const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter your email please",
      label: "Please enter your email",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter your password please",
      label: "Please enter your password",
    },
  },
  signUp: {
    confirm_password: {
      labelHidden: false,
      label: "Confirm Password:",
      isRequired: true,
      order: 4,
    },
    password: {
      labelHidden: false,
      label: "Password:",
      placeholder: "Enter your new Password *",
      isRequired: true,
      order: 3,
    },
    email: {
      labelHidden: false,
      label: "Email:",
      placeholder: "Please enter your Email",
      isRequired: true,
      order: 2,
    },
    name: {
      labelHidden: false,
      label: "Username:",
      placeholder: "This is the name you'll be called with in-game",
      isRequired: true,
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: "Please enter teh email you registered with:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: "Enter your Confirmation Code:",
      label: "Confirmation code",
    },
    confirm_password: {
      labelHidden: false,
      placeholder: "Enter your Password Please:",
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: "Confirmation code",
      placeholder: "Enter your Confirmation Code:",
      isRequired: true,
    },
  },
};
</script>
<script>
export default {
  components: { tiLogo },
  data() {
    return {
      listener: (data) => {
        switch (data.payload.event) {
          case "signIn":
            this.$store.commit("login");
            this.getCurrentUser(data.payload.data.attributes).then(() => {
              this.$store.commit("unlock");
            });
            break;
        }
      },
    };
  },
  async created() {
    // This switch listens to auth events
    Hub.listen("auth", this.listener);
    // listening for achievments
    this.emitter.on("achievement", (ach) => {
      if (ach != false) {
        this.newAchievment(ach);
      }
    });
  },
  methods: {
    /**
     * This function is used to generate a new gameuser within amplify after initial regitration of an AWSCognitoUser
     */
    async addUser(userAttributes) {
      try {
        await API.graphql(
          graphqlOperation(createUser, {
            input: {
              Name: userAttributes.name,
              Mail: userAttributes.email,
              Score: 0,
              BuisnessUnit: "681e363c-3a56-4c08-81f9-63b55b54d686", //this needs to be changed in the future when multiple BuisnessUnits are used
              Phished: true,
              CognitoID: userAttributes.sub,
            },
          })
        );
      } catch (addUserError) {
        console.error({ addUserError });
      }
    },
    /**
     * This function will find the gameUser that matches the current signed in Cognito User
     */
    async getCurrentUser(userAttributes) {
      try {
        let findUser = await API.graphql(
          graphqlOperation(listUsers, {
            filter: { CognitoID: { eq: userAttributes.sub } },
          })
        );
        let res = findUser.data.listUsers.items[0];
        if (res == null) {
          this.addUser(userAttributes);
          findUser = await API.graphql(
            graphqlOperation(listUsers, {
              filter: { CognitoID: { eq: userAttributes.sub } },
            })
          );
          res = findUser.data.listUsers.items[0];
        }
        this.$store.commit("setUser", JSON.stringify(res));
        this.newAchievment("ed79efbd-f74c-43d2-9502-7302e6a017b4");
      } catch (getCurrentUserError) {
        console.error({ getCurrentUserError });
      }
    },
    /**
     * This function will assign an achievment to the user if it is new
     */
    async newAchievment(id) {
      try {
        const toast = useToast();
        // call the API and ask whether the mail was correctly identified
        const response = await API.post("restBackend", "/achievement", {
          body: { id: id, userID: JSON.parse(this.$store.state.user)?.id },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.success) {
          toast.info(response.name, { icon: <Trophy /> });
        }
      } catch (newAchievementError) {
        console.error({ newAchievementError });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
body {
  margin: 0 !important;
}
</style>
