<!-- This component switches between the -->
<template>
  <div class="tabs">
    <slot />
    <ul
      id="menu-bar"
      class="tabs_header"
    >
      <li
        v-for="tab in tabs"
        :key="tab.props.title"
        class="menu-items"
        @click="switchTab(tab)"
      >
        <div
          v-if="tab.props.icon == 'email'"
          style="position: relative"
        >
          <email-outline
            id="icon-mail"
            class="menu-icon"
            :class="{ active: tab.props.title == store.state.selectedTitle }"
          />
          <div id="progIndicator">
            {{ prog }}
          </div>
        </div>
        <compass
          v-if="tab.props.icon == 'compass'"
          id="icon-compass"
          class="menu-icon"
          :class="{ active: tab.props.title == store.state.selectedTitle }"
        />
        <magnify
          v-if="tab.props.icon == 'magnifier'"
          id="icon-magnify"
          class="menu-icon"
          :class="{ active: tab.props.title == store.state.selectedTitle }"
        />
        <account
          v-if="tab.props.icon == 'account'"
          id="icon-account"
          class="menu-icon"
          :class="{ active: tab.props.title == store.state.selectedTitle }"
        />
        <flag-checkered
          v-if="tab.props.icon == 'flag'"
          id="icon-end"
          class="menu-icon"
          :class="{ active: tab.props.title == store.state.selectedTitle }"
        />
        <book-information-variant
          v-if="tab.props.icon == 'book'"
          id="icon-start"
          class="menu-icon"
          :class="{ active: tab.props.title == store.state.selectedTitle }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import { API, graphqlOperation } from "aws-amplify";
import {
  EmailOutline,
  Compass,
  Magnify,
  Account,
  FlagCheckered,
  BookInformationVariant,
} from "mdue";
import { useStore } from "vuex";
import { getProgress } from "@/graphql/customQueries";

export default {
  components: {
    EmailOutline,
    Compass,
    Magnify,
    Account,
    FlagCheckered,
    BookInformationVariant,
  },
  setup(props, { slots }) {
    //Retrieve the information of all included tabs
    const tabs = ref(slots.default().map((tab) => tab));
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const store = useStore();
    store.commit("selectTitle", tabTitles.value[0]);
    return {
      tabs,
      store,
      tabTitles,
    };
  },
  data() {
    return {
      prog: 1, //the number of mails left (default 1)
    };
  },
  async created() {
    this.prog = await this.calcProgress();
  },
  mounted() {
    this.emitter.on("nextMail", async () => {
      this.prog = await this.calcProgress();
    });
  },
  methods: {
    switchTab(tab) {
      this.$store.commit("selectTitle", tab.props.title);
      window.scrollTo(0, 0);
    },
    // retrieves hte remaining mails
    async calcProgress() {
      try {
        let ret = await API.graphql(
          graphqlOperation(getProgress, {
            id: this.$store.getters.progress,
          })
        );
        return ret.data.getProgress.Goal - ret.data.getProgress.Progress;
      } catch (getProgressError) {
        return 1;
      }
    },
  },
};
</script>

<style>
#menu-bar {
  list-style: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #90bc15;
  margin: 0px !important;
  padding: 0px !important;
  height: 80px;
}

.menu-items {
  float: left;
}

.menu-icon {
  margin: 10px 0px 0px 50px !important;
  font-size: 64px;
  border-radius: 32px;
  background-color: white;
}
.active {
  background-color: grey !important;
}
.tab-content {
  padding-bottom: 80px !important;
}
#progIndicator {
  position: absolute;
  top:0;
  right:0;
  background-color: #fa3e3e;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  font-weight: bold;
}
</style>
