<template>
  <svg
    id="ti"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 120"
    style="enable-background: new 0 0 500 120"
    xml:space="preserve"
  >
    <title> The TI Logo "Team Internet - Ideas change Markets"</title>
    <g id="claim">
      <path
        d="M133.4,95.7c0-6.7,0-13.9,0-13.9c0-0.8,0-0.8,1.4-0.8c0,0,0,8.1,0,14.7c0,7.6,0,14.3,0,14.3c0,0.5,0,0.5-1.4,0.5
        C133.3,110.4,133.4,103.3,133.4,95.7z"
      />
      <path
        d="M149.9,80.2c0-0.8,0.4-0.8,1.4-0.8v30.3c-1.3,0.8-3.5,1.3-5.9,1.3c-3.8,0-6.9-1.3-6.9-10.7c0-7.9,3.5-11.5,7.5-11.5
        c1.6,0,2.8,0.3,3.8,1V80.2z M149.9,91.2c-1-0.8-1.8-1.1-3.5-1.1c-3.5,0-6.3,3.4-6.3,10.2c0,8.5,2.4,9.4,5.7,9.4
        c1.6,0,3.1-0.3,4.2-1L149.9,91.2z"
      />
      <path
        d="M167.9,95.4c0,1.7-0.1,3.6-0.3,4.9c-3.5,0.7-7.6,0.8-9.8,0.8c-0.6,0-1.1,0-1.3,0v0.2c0,7.6,2.4,8.5,5.7,8.5
        c2.1,0,4-0.5,5.4-1.5c0.2,0.4,0.2,1,0.2,1.3c0,0.4-2.6,1.6-5.6,1.6c-4,0-7-1.3-7-10c0-11.5,4.1-12.3,7.5-12.3
        C166.4,88.7,167.9,91.4,167.9,95.4z M156.5,99.7c1.4,0,6.7,0.1,9.9-0.6c0.1-1.2,0.2-2.3,0.2-3.2c-0.1-3.6-0.9-5.8-3.9-5.8
        C160.1,90.1,156.8,90.5,156.5,99.7z"
      />
      <path
        d="M170.5,90.4c0-0.4,0.1-0.5,0.2-0.6c0.9-0.5,4.2-1,7.4-1c2.5,0,4.3,1.3,4.3,5.1v2.9c0,7.6-0.2,12.9-0.2,12.9
        c-0.8,0.5-2.5,1.4-5.6,1.4c-3,0.1-6.1-0.3-6.1-6.4c0-6,3.4-6.8,6.8-6.8c1.3,0,2.7,0.1,3.7,0.4c0,0,0-3,0-4.2c0-3.4-1.7-4-3.6-4
        c-2.4,0-5.6,0.4-6.8,1.1C170.6,90.9,170.5,90.6,170.5,90.4z M181,99.5c-1-0.3-2.4-0.4-3.4-0.4c-2.9,0-5.8,0.4-5.8,5.8
        c0,4.9,2.4,4.9,5,4.9c1.7,0,3.4-0.5,3.9-1.1C180.8,108.7,181,102.7,181,99.5z"
      />
      <path
        d="M198.1,90.6c0,0.3-0.2,0.7-0.4,1c-1.5-1-2.9-1.5-5-1.5c-3,0-5.5,1-5.5,3.1c0,1.2,0.6,2.1,1.9,3l5.8,4.2
        c2.1,1.5,3.1,3.1,3.1,5c0,4-2.5,5.8-6.8,5.8c-3.5,0-5.5-1-5.5-1.5c0-0.3,0.2-0.7,0.5-1c1.3,0.8,2.5,1.1,4.8,1.1
        c3.3,0,5.5-1.2,5.5-4.4c0-1.5-0.9-2.8-2.9-4.2l-5.2-3.7c-2.1-1.5-2.7-2.6-2.7-4.3c0-3.1,3-4.5,6.9-4.5
        C196,88.7,198.1,90.1,198.1,90.6z"
      />
      <path
        d="M202.9,107.7c0.9,0,1.4,0.7,1.4,1.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.3-0.6-1.3-1.5C201.5,108.4,202.1,107.7,202.9,107.7z"
      />
      <path
        d="M231.7,82.2c0,0.4-0.2,0.8-0.3,1c-0.3-0.3-1.7-1.5-3.3-1.5c-3.4,0-6.9,1.7-6.9,15.8c0,10.5,3,12.2,6,12.2
        c1.8,0,3.9-1.3,4.4-2.4c0.2,0.3,0.3,0.8,0.3,1.2c0,0.6-2.4,2.6-4.9,2.6c-3.2,0-7.4-0.6-7.4-13.8c0-15.3,4.4-16.9,8.1-16.9
        C230.2,80.3,231.7,81.8,231.7,82.2z"
      />
      <path
        d="M235.2,80.2c0-0.8,0.3-0.8,1.4-0.8v11.2c1.8-1.4,3.8-1.9,5.7-1.9c4.3,0,5.3,1.3,5.3,9V110c0,0.5,0,0.5-1.4,0.5V97.6
        c0-6.6-0.8-7.5-4.1-7.5c-1.6,0-3.9,0.5-5.6,2.2V110c0,0.5,0,0.5-1.4,0.5V80.2z"
      />
      <path
        d="M250.9,90.4c0-0.4,0.1-0.5,0.2-0.6c0.9-0.5,4.2-1,7.4-1c2.5,0,4.3,1.3,4.3,5.1v2.9c0,7.6-0.2,12.9-0.2,12.9
        c-0.8,0.5-2.5,1.4-5.6,1.4c-3,0.1-6.1-0.3-6.1-6.4c0-6,3.4-6.8,6.8-6.8c1.3,0,2.7,0.1,3.8,0.4c0,0,0-3,0-4.2c0-3.4-1.7-4-3.6-4
        c-2.4,0-5.6,0.4-6.8,1.1C250.9,90.9,250.9,90.6,250.9,90.4z M261.4,99.5c-1-0.3-2.4-0.4-3.4-0.4c-2.9,0-5.8,0.4-5.8,5.8
        c0,4.9,2.4,4.9,5,4.9c1.7,0,3.4-0.5,3.9-1.1C261.2,108.7,261.4,102.7,261.4,99.5z"
      />
      <path
        d="M266.9,90.1c2.1-0.9,5-1.4,7-1.4c5.2,0,5.6,2.1,5.6,8.1V110c0,0.5,0,0.5-1.4,0.5V96.6c0-5.1-0.3-6.6-4.3-6.6
        c-1.5,0-4,0.3-5.6,1.1V110c0,0.5,0.1,0.5-1.3,0.5V90.1z"
      />
      <path
        d="M297.2,89c0,0.1,0.1,0.3,0.1,0.6c0,0.4-0.2,0.7-0.8,0.7c-0.9,0-2.1-0.1-3-0.2c1.9,0.8,3,2.9,3,5.8c0,4.3-1.9,8.1-6.8,8.1
        c-1.1,0-2-0.1-2.8-0.4c-1.1,0.8-2.4,2.1-2.4,3.1c0,0.7,3,1.3,7.1,1.8c2.8,0.4,5.6,1.1,5.6,4.5c0,2.9-2.7,5.9-8.2,5.9
        c-5.6,0-6.6-2.5-6.6-4.8c0-2.1,1.9-4,3.6-5.1c-1.8-0.5-2.9-1.1-2.9-1.8c0-1.3,1.5-3,2.7-4c-2.2-1.3-2.7-3.9-2.7-6.6
        c0-5,3.1-7.8,6.8-7.8c0.4,0,1.2,0.1,1.9,0.2H297.2z M283.9,113.9c0,1.8,0.8,3.6,5.3,3.6c4.5,0,6.8-2.2,6.8-4.4
        c0-2.6-2.2-3.1-4.8-3.3c-1.3-0.1-2.6-0.3-3.7-0.5C285.8,110.2,283.9,112.1,283.9,113.9z M290,90c-2.9,0-5.4,2.2-5.4,6.6
        c0,3,0.7,6.2,5.3,6.2c3.7,0,5.3-3.5,5.3-7C295.2,92,293.2,90,290,90z"
      />
      <path
        d="M312.2,95.4c0,1.7-0.1,3.6-0.3,4.9c-3.5,0.7-7.6,0.8-9.8,0.8c-0.6,0-1.1,0-1.3,0v0.2c0,7.6,2.4,8.5,5.7,8.5
        c2.1,0,4-0.5,5.3-1.5c0.2,0.4,0.2,1,0.2,1.3c0,0.4-2.6,1.6-5.6,1.6c-4,0-7-1.3-7-10c0-11.5,4.1-12.3,7.5-12.3
        C310.8,88.7,312.2,91.4,312.2,95.4z M300.8,99.7c1.4,0,6.7,0.1,9.9-0.6c0.1-1.2,0.2-2.3,0.2-3.2c-0.1-3.6-0.9-5.8-3.9-5.8
        C304.5,90.1,301.1,90.5,300.8,99.7z"
      />
      <path
        d="M316.8,107.7c0.9,0,1.4,0.7,1.4,1.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.3-0.6-1.3-1.5C315.5,108.4,316,107.7,316.8,107.7z"
      />
      <path
        d="M334.5,81.4c0,0,0-0.5,0.5-0.5h0.8l5.1,16.5c0.1,0.3,1.6,5.4,1.6,5.4s1.5-4.9,1.6-5.2l5.1-16.2c0-0.1,0.1-0.5,0.5-0.5h0.9
        v29c0,0.5,0,0.5-1.4,0.5l0-14c0-0.4,0-8,0.1-11.2c-0.9,3.1-3.5,11.2-3.5,11.4l-2.6,7.7c-0.1,0.3-0.3,0.4-0.5,0.4h-0.8l-2.5-8.1
        c0-0.2-2.5-7.9-3.5-11.2c0,2.6,0.1,10.2,0.1,10.6V110c0,0.5,0,0.5-1.4,0.5V81.4z"
      />
      <path
        d="M354.1,90.4c0-0.4,0.1-0.5,0.2-0.6c0.9-0.5,4.2-1,7.4-1c2.5,0,4.3,1.3,4.3,5.1v2.9c0,7.6-0.2,12.9-0.2,12.9
        c-0.8,0.5-2.5,1.4-5.6,1.4c-3,0.1-6.1-0.3-6.1-6.4c0-6,3.4-6.8,6.8-6.8c1.3,0,2.7,0.1,3.8,0.4c0,0,0-3,0-4.2c0-3.4-1.7-4-3.6-4
        c-2.4,0-5.6,0.4-6.8,1.1C354.1,90.9,354.1,90.6,354.1,90.4z M364.6,99.5c-1-0.3-2.4-0.4-3.4-0.4c-2.9,0-5.8,0.4-5.8,5.8
        c0,4.9,2.4,4.9,5,4.9c1.7,0,3.4-0.5,3.9-1.1C364.4,108.7,364.6,102.7,364.6,99.5z"
      />
      <path
        d="M370.1,90.6c2-1.4,3.9-1.9,5.5-1.9c2,0,3.2,0.6,3.2,1.1c0,0.3-0.1,0.9-0.3,1.1c-0.6-0.4-1.3-0.8-2.7-0.8
        c-1.6,0-3.1,0.5-4.3,1.4V110c0,0.5,0,0.5-1.4,0.5V90.6z"
      />
      <path
        d="M381.7,80.2c0-0.8,0.3-0.8,1.4-0.8v12.3c2.1-2.4,4.7-3,6.7-3c2.4,0,3.5,1.7,3.5,3.7c0,4-3.8,6.4-5.2,7
        c1.8-0.2,5.1,1.2,6,5.8c0.1,0.7,0.2,1.4,0.2,2.2c0,0.8,0,1.6-0.2,2.4c-0.1,0.5,0,0.5-1.5,0.5c0.1-1.1,0.2-2,0.2-2.8
        c0-1.6-0.3-2.8-0.6-3.8c-1.1-2.7-3.2-3.5-4.9-3.5c-0.5,0-1,0.1-1.3,0.2c-0.8,0.3-3,1.1-3,1.1v8.3c0,0.5,0.1,0.5-1.4,0.5V80.2z
            M391.9,92.6c0-1.4-0.8-2.6-2.4-2.6c-2.1,0-4.1,0.6-6.4,3.4v7C387.9,98.9,391.9,96.2,391.9,92.6z"
      />
      <path
        d="M409.9,95.4c0,1.7-0.1,3.6-0.3,4.9c-3.5,0.7-7.6,0.8-9.8,0.8c-0.6,0-1.1,0-1.3,0v0.2c0,7.6,2.4,8.5,5.7,8.5
        c2.1,0,4-0.5,5.3-1.5c0.2,0.4,0.2,1,0.2,1.3c0,0.4-2.6,1.6-5.6,1.6c-4,0-7-1.3-7-10c0-11.5,4.1-12.3,7.5-12.3
        C408.4,88.7,409.9,91.4,409.9,95.4z M398.5,99.7c1.4,0,6.7,0.1,9.9-0.6c0.1-1.2,0.2-2.3,0.2-3.2c-0.1-3.6-0.9-5.8-3.9-5.8
        C402.1,90.1,398.8,90.5,398.5,99.7z"
      />
      <path
        d="M415.6,90.7h-3.4c0-1.1,0-1.3,0.4-1.3h3v-6.9c0-0.7,0.2-0.7,1.4-0.7v7.6h5.7c0,1.1,0,1.3-0.3,1.3H417v13.7
        c0,3.1,0.1,5.4,2.3,5.4c1.4,0,2-0.3,2.9-0.7c0.1,0.2,0.1,0.7,0.1,1c0,0.5-1.6,1-3.3,1c-3.5,0-3.5-2.9-3.5-6.8V90.7z"
      />
      <path
        d="M437.5,90.6c0,0.3-0.2,0.7-0.4,1c-1.5-1-2.9-1.5-5-1.5c-3,0-5.5,1-5.5,3.1c0,1.2,0.6,2.1,1.9,3l5.8,4.2
        c2.1,1.5,3.1,3.1,3.1,5c0,4-2.5,5.8-6.8,5.8c-3.5,0-5.5-1-5.5-1.5c0-0.3,0.2-0.7,0.5-1c1.3,0.8,2.5,1.1,4.8,1.1
        c3.3,0,5.5-1.2,5.5-4.4c0-1.5-0.9-2.8-2.9-4.2l-5.2-3.7c-2.1-1.5-2.7-2.6-2.7-4.3c0-3.1,3-4.5,6.9-4.5
        C435.4,88.7,437.5,90.1,437.5,90.6z"
      />
      <path
        d="M442.3,107.7c0.9,0,1.4,0.7,1.4,1.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.3-0.6-1.3-1.5C441,108.4,441.5,107.7,442.3,107.7z"
      />
    </g>
    <g id="brand">
      <path d="M133.4,18.6H124V9.1h29.1v9.5h-9.5v43.6h-10.3V18.6z" />
      <path
        d="M161.1,46.6c0.1,6,3.6,8.2,8.1,8.2c2.9,0,5.4-0.6,7.3-1.3l1.3,7.4c-2.6,1-6.6,1.7-10.5,1.7c-10.6,0-16.3-6.9-16.3-19
        c0-13,6.8-20.7,15.4-20.7c8.3,0,13.1,6.5,13.1,18.3c0,2.8-0.2,4.3-0.3,5.4H161.1z M170.2,39.3c0-5-1.3-8.8-4.3-8.8
        c-3.3,0-4.7,5-4.8,8.8H170.2z"
      />
      <path
        d="M210.5,53.3c0,3.1,0.2,6.2,0.6,8.8h-9.2l-0.6-3.8h-0.2c-2,2.9-5.1,4.4-8.5,4.4c-5.8,0-10.2-4.9-10.2-11.7
        c0-10.2,8.9-14.3,17.6-14.3v-0.9c0-3.1-1.7-5.3-5.4-5.3c-3.1,0-5.5,0.9-7.9,2.3l-1.9-7c2.2-1.2,6.6-2.9,12.1-2.9
        c11.7,0,13.6,8.2,13.6,16.1V53.3z M200.4,43.4c-3.4,0.1-8.2,1.3-8.2,6.6c0,3.8,2.1,5.2,3.7,5.2c1.7,0,3.5-1,4.3-3.3
        c0.2-0.6,0.2-1.1,0.2-1.8V43.4z"
      />
      <path
        d="M216.6,35.2c0-4.2-0.2-7.9-0.3-11.6h9l0.6,4.4h0.2c2-2.8,5.2-5.1,9.1-5.1c4.3,0,7.1,2,8.4,5.3h0.2c0.9-1.5,2.4-2.9,3.9-3.9
        c1.6-0.9,3.4-1.4,5.4-1.4c6.9,0,10,5.5,10,14.7v24.5h-10.3V39.4c0-4.3-0.5-7.9-3.5-7.9c-1.8,0-3.2,1.3-3.9,3.5
        c-0.2,0.6-0.3,1.7-0.3,2.8v24.4h-10.3V38c0-2.8-0.2-6.5-3.5-6.5c-1.6,0-3.2,1.1-3.9,3.5c-0.2,0.7-0.4,1.8-0.4,2.9v24.3h-10.3V35.2z
        "
      />
      <path d="M294.1,9.1v53.1h-10.3V9.1H294.1z" />
      <path
        d="M300.9,35.2c0-4.2-0.2-7.9-0.3-11.6h8.9L310,28h0.2c2-3,5.3-5,9.3-5c7.3,0,10.2,5.8,10.2,14.4v24.7h-10.3V38.1
        c0-3.2-0.8-6.4-3.8-6.4c-1.7,0-3.1,1.2-3.9,3.5c-0.3,0.8-0.6,2-0.6,3.5v23.5h-10.3V35.2z"
      />
      <path
        d="M347.9,13.6v10h7.6V31h-7.6v17.6c0,4.5,1.9,5.6,3.9,5.6c1,0,1.8-0.1,2.8-0.2l0.1,8c-1.4,0.4-3.6,0.7-6.3,0.7
        c-3,0-5.6-0.7-7.5-2.4c-2-2-3.2-5-3.2-11V31H333v-7.3h4.6v-6.8L347.9,13.6z"
      />
      <path
        d="M367.3,46.6c0.1,6,3.6,8.2,8.1,8.2c2.9,0,5.4-0.6,7.3-1.3l1.3,7.4c-2.6,1-6.6,1.7-10.5,1.7c-10.6,0-16.3-6.9-16.3-19
        c0-13,6.8-20.7,15.4-20.7c8.3,0,13.1,6.5,13.1,18.3c0,2.8-0.2,4.3-0.3,5.4H367.3z M376.4,39.3c0-5-1.3-8.8-4.3-8.8
        c-3.3,0-4.7,5-4.8,8.8H376.4z"
      />
      <path
        d="M390.6,35.2c0-3.8-0.2-8-0.3-11.6h8.9l0.6,5.6h0.3c1.7-4.1,4.8-6.3,7.6-6.3c1.1,0,1.7,0.1,2.2,0.2v10.2
        c-0.6-0.1-1.3-0.1-2.2-0.1c-2.2,0-5.1,1.5-6.2,5.4c-0.3,0.9-0.5,2.3-0.5,3.9v19.6h-10.3V35.2z"
      />
      <path
        d="M413.3,35.2c0-4.2-0.2-7.9-0.3-11.6h8.9l0.5,4.4h0.2c2-3,5.3-5,9.3-5c7.3,0,10.2,5.8,10.2,14.4v24.7h-10.3V38.1
        c0-3.2-0.8-6.4-3.8-6.4c-1.7,0-3.1,1.2-3.9,3.5c-0.3,0.8-0.5,2-0.5,3.5v23.5h-10.3V35.2z"
      />
      <path
        d="M456.8,46.6c0.1,6,3.6,8.2,8.1,8.2c2.9,0,5.4-0.6,7.3-1.3l1.3,7.4c-2.6,1-6.6,1.7-10.5,1.7c-10.6,0-16.3-6.9-16.3-19
        c0-13,6.8-20.7,15.4-20.7c8.3,0,13.1,6.5,13.1,18.3c0,2.8-0.2,4.3-0.3,5.4H456.8z M465.9,39.3c0-5-1.3-8.8-4.3-8.8
        c-3.3,0-4.7,5-4.8,8.8H465.9z"
      />
      <path
        d="M492.4,13.6v10h7.6V31h-7.6v17.6c0,4.5,1.9,5.6,3.9,5.6c1,0,1.8-0.1,2.8-0.2l0.1,8c-1.4,0.4-3.6,0.7-6.3,0.7
        c-3,0-5.6-0.7-7.5-2.4c-2-2-3.2-5-3.2-11V31h-4.6v-7.3h4.6v-6.8L492.4,13.6z"
      />
    </g>
    <g id="icon">
      <path
        class="st0"
        d="M90.5,13.5c0,7.5-6.1,13.5-13.5,13.5c-7.5,0-13.5-6.1-13.5-13.5C63.5,6.1,69.5,0,77,0
        C84.5,0,90.5,6.1,90.5,13.5"
      />
      <path
        d="M89.9,53.2c0-12.2-9.9-22.1-22.1-22.1H35.3V2.7L10.8,9.1v22H0V56h10.8v32.4c0,12.2,9.9,22.1,22.1,22.1h22.9V85.8H42.7
        c-4,0-7.2-3.3-7.2-7.2V56h24.3c3.2,0.8,5.6,3.7,5.6,7.1l0,47.4h24.6V53.2z"
      />
    </g>
  </svg>
</template>
<script>
export default {
    name:"TiLogo"
}
</script>
<style scoped>
.st0 {
  fill: #b7f30f;
  fill: #90bc15;
}
</style>
