<template>
  <template v-if="!store.getters.locked">
    <div id="logo">
      <ti-logo />
    </div>
    <div id="onboarding">
      <h1>Welcome to TeamInternet's Phishing Prevention Game</h1>
      <h2>Table of Content</h2>
      <ul id="toc">
        <li @click="scrollTo('#introduction')">
          Introduction
        </li>
        <li @click="scrollTo('#team')">
          The Team
        </li>
        <li>
          <span @click="scrollTo('#tutorial')">How it Works</span>
          <ul>
            <li @click="scrollTo('#explainIcons')">
              Clickable Icons
            </li>
            <li @click="scrollTo('#mailExp')">
              The Mail Client
            </li>
            <li @click="scrollTo('#acc')">
              Your profile
            </li>
            <li @click="scrollTo('#browser')">
              The Browser
            </li>
            <li @click="scrollTo('#doc')">
              The Document Viewer
            </li>
          </ul>
        </li>
      </ul>
      <hr class="divider">
      <h2 id="introduction">
        Introduction
      </h2>
      <p class="explanation">
        First off, thanks for participating. This game is being developed as
        part of <span title="Thorsten Rösler">my </span> Master Thesis. The goal
        is to introduce our employees to the risks of Social Engineering, most
        notably phishing attacks, and show them how to prevent getting
        compromised in a fun way. <br>
        This page will introduce you to the game mechanics as well as the
        scenario you'll be facing. In the end, there will also be a
        questionnaire. I would really appreciate your participation because this
        will be an important part of my Master's Thesis. If you have any
        questions or problems, feel free to contact me either via slack
        @Thorsten Rösler or via mail
        <a href="mailto:thorsten.roesler@teaminternet.com">thorsten.roesler@teaminternet.com</a>.<br>
        But enough pre skirmish, this is the scenario: <br>
        You are a product manager in the RandomTechCompany. The company has 10
        employees and is in the final phase of releasing a major software
        product they've previously announced. As expected there are lots of
        problems to solve for you, like answering requests from your team,
        talking to externals or hiring a new AWS Developer to handle the
        expected traffic load once the software is released. 
        You just put out the job listing yesterday. Maybe someone will reachout to you today. <br>
        And as of that were not enough, you are facing a vast amount of spam mails. Your job is to
        do your daily work while filtering out all spam mails.
      </p>
      <hr class="divider">
      <h2 id="team">
        The Team
      </h2>
      <div id="teamTable">
        <team-member-card
          image="avatar-lucy.jpg"
          name="Lucy"
          position="CEO"
        />
        <team-member-card
          image="avatar-tom.jpg"
          name="Tom"
          position="Marketing & PR"
        />
        <team-member-card
          image="avatar-mark.jpg"
          name="Mark"
          position="IT Administrator"
        />
        <team-member-card
          image="avatar-xiuying.jpg"
          name="Xiuying"
          position="Senior Software Engineer"
        />
        <team-member-card
          image="avatar-thorsten.jpg"
          name="Thorsten"
          position="Software Engineer"
        />
        <team-member-card
          image="avatar-henry.jpg"
          name="Henry"
          position="Software Engineer"
        />
        <team-member-card
          image="avatar-tim.jpg"
          name="Tim"
          position="Software Engineer"
        />
        <team-member-card
          image="avatar-clara.jpg"
          name="Clara"
          position="Accounting"
        />
        <team-member-card
          image="avatar-mara.jpg"
          name="Mara"
          position="Working Student"
        />
        <team-member-card
          image="avatar-you.svg"
          :name="name"
          position="Product Manager"
        />
      </div>
      <p class="explanation">
        If you want to start the game immediately press the button below.
        otherwise continue with the explanation and open the mail client
        afterwards.
      </p>
      <button
        id="startGame"
        @click="store.commit('selectTitle','Mail')"
      >
        Start the Game
      </button>
      <hr class="divider">
      <h2 id="tutorial">
        How the game works
      </h2>
      <p class="explanation">
        The game simulates a slimmed-down office PC. You have your mail client,
        your browser and a document viewer. The alter are only needed to
        interact with files or links from emails. There is also the profile tab
        as well as this screen here. You can switch between those tabs at any
        time by clicking on the corresponding icon in the taskbar down below.
        Here you can see all the clickable icons the game confronts you with
        sooner or later:
      </p>
      <hr class="divider">
      <div id="explainIcons">
        <div class="iconWrapper">
          <email-outline class="icon" />
          The Mail Client
        </div>
        <div class="iconWrapper">
          <compass class="icon" />
          The Browser
        </div>
        <div class="iconWrapper">
          <magnify class="icon" />
          The Document Viewer
        </div>
        <div class="iconWrapper">
          <account class="icon" />
          Your profile
        </div>
        <div class="iconWrapper">
          <flag-checkered class="icon" />
          The final report
        </div>
        <div class="iconWrapper">
          <book-information-variant class="icon" />
          This Introduction
        </div>
        <div class="iconWrapper">
          <archive-alert class="icon" />
          Mark a Mail as spam
        </div>
        <div class="iconWrapper">
          <email-mark-as-unread class="icon" />
          Mark a mail as normal
        </div>
        <div class="iconWrapper">
          <attachment class="icon" />
          Open an attached file
        </div>
      </div>
      <hr class="divider">
      <h3 id="mailExp">
        The Mail Client
      </h3>
      <p class="explanation">
        Within the mail client, you can read and interact with emails you've
        received. You can inspect the sender, the subject, as well as the
        content and attachments to identify a mail either as phish or harmless.
        Most of the emails ask you to click on links or open documents in order
        to perform certain tasks. And while you could say that you'll never open
        such things from emails, this isn't always feasible. Therefore you have
        to learn how to identify phishy looking emails and how to decide whether
        a link or an attachment is safe to open or not.<br>
        With each mail, you can decide whether it is a phish or not by clicking
        either the "Mark a Mail as spam" or the "Mark a Mail as normal" button.
        If you've chosen correctly, you'll retrieve points depending on the
        difficulty of the mail.
      </p>
      <hr class="divider">
      <h3 id="acc">
        Your profile
      </h3>
      <p class="explanation">
        You can see your current score on the Account page. There you'll also be
        able to log out and see a history of all played emails. There is also a
        list of all the achievements you've earned and the high-score table. To
        make this game as inclusive and fun as possible, all names but your own
        are randomized.
      </p>
      <hr class="divider">
      <h3 id="browser">
        The Browser
      </h3>
      <p class="explanation">
        When you've clicked on a link, you'll automatically get redirected to
        the browser. Here, you can inspect and interact with the website. You
        can also copy the link from the URL bar. Currently, it isn't possible to
        manually open other sites, so when you want to
        <a
          href="http://checkshorturl.com/"
          target="blank"
        >extend links</a> or
        check whether all the characters are
        <a
          href="https://r12a.github.io/app-listcharacters/"
          target="blank"
        >who they claim to be</a>, you'll need to use your real browser.
      </p>
      <hr class="divider">
      <h3 id="doc">
        The Document Viewer
      </h3>
      <p class="explanation">
        When you click on an attachment, it will be displayed here. To simplify
        the process this program automatically displays all file types. You
        aren't able to edit anything or interact with the documents in any form
        but by reading and copying content from here or open links.
      </p>
    </div>
  </template>
  <template v-else>
    <!-- Spinner from https://dribbble.com/shots/2882839-Material-Spinner-Principle-File-Attached -->
    <img
      id="loading-spinner"
      src="/spinner-loop.gif"
      alt="Loading spinner"
    >
  </template>
</template>

<script setup>
import { useStore } from "vuex";
import {
  EmailOutline,
  Compass,
  Magnify,
  Account,
  FlagCheckered,
  BookInformationVariant,
  ArchiveAlert,
  EmailMarkAsUnread,
  Attachment,
} from "mdue";
import TeamMemberCard from "./TeamMemberCard.vue";
import TiLogo from "./TiLogo.vue";
const store = useStore();
let name = "";
if (!store.getters.locked) name = JSON.parse(store.state.user).Name;
function scrollTo(id) {
  $(window).scrollTop($(id).offset().top);
}
</script>
<script>
export default {
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  watch: {
    locked(val) {
      if (!val) {
        this.name = JSON.parse(this.$store.state.user).Name;
      }
    },
  },
};
</script>

<style scoped>
#logo {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}
.explanation {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  hyphens: auto;
  font-size: 18pt;
}
#teamTable {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  column-gap: 15px;
  row-gap: 30px;
  justify-content: center;
  margin-bottom: 30px;
}
.divider {
  border-top: 3px solid #90bc15;
  border-radius: 5px;
  margin: 5px 15px;
}
#explainIcons {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  column-gap: 15px;
  row-gap: 30px;
  justify-content: center;
  margin-bottom: 30px;
}
.icon {
  font-size: 100px;
  background-color: white;
}
.icon:hover {
  background-color: #90bc15;
  border-radius: 50px;
}
.iconWrapper {
  position: relative;
  width: 120px;
}
#toc {
  list-style: circle;
  text-align: left;
  font-size: 20pt;
  cursor: pointer;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
li:hover {
  color: #90bc15;
}
li:hover ul {
  color: #000;
}
#startButton {
  margin-left: auto;
  margin-right: auto;
}
</style>
