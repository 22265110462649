<template>
  <span
    id="mailBody"
    v-html="mailBody"
  />
</template>

<script setup>
import { API, graphqlOperation } from "aws-amplify";
import { listURLS } from "../graphql/queries";
import { useStore } from "vuex";
const store = useStore();
let mailData = {};
try {
  mailData = JSON.parse(store.getters.currentMessage);
} catch (e) {
  console.error(e);
}
</script>
<script>
export default {
  data() {
    return {
      url: {},
      mailBody: "",
    };
  },
  // replace the template values with actual content
  created() {
    this.mailData = JSON.parse(this.$store.getters.currentMessage);
    if (Object.keys(this.mailData).length !== 0) {
      this.renderBody();
    }
    this.emitter.on("newContent", (newMailData) => {
      this.mailData = newMailData;
      this.renderBody();
      $(".link").click(this.clickHref);
    });
  },
  mounted() {
    // adds the onclick listener to all lik elements within the mail
    $(".link").click(this.clickHref);
    // because no form of listener reliably worked there is now this awful timer to add this regularly
    window.setInterval(() => {
      $(".link").unbind();
      $(".link").click(this.clickHref);
    }, 2000);
    // Select the target node.
    var target = document.querySelector("#mailBody");

    // Create an observer instance.
    var observer = new MutationObserver(function (mutations) {
      $(".link").click(this.clickHref);
    });

    // Pass in the target node, as well as the observer options.
    observer.observe(target, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  },
  methods: {
    // switches to browser and emits the clicked link data
    openLink() {
      this.emitter.emit("open-link", this.url);
      this.$store.commit("selectTitle", "Browser");
    },
    /**
     * This method is called to update the mail Body
     */
    renderBody() {
      this.mailBody = this.mailData?.body?.replace(
        "__NAME__",
        this.mailData?.receiverName
      );
      this.retrieveUrl(this.mailData?.urlId).then((res) => {
        this.url = res;
      });
    },
    /**
     * This method retrieves the data for the URL corresponding to the mail
     */
    async retrieveUrl(urlId) {
      try {
        const urls = await API.graphql(
          graphqlOperation(listURLS, { filter: { id: { eq: urlId } } })
        );
        return urls.data.listURLS.items[0];
      } catch (retrieveUrlError) {
        console.error({ retrieveUrlError });
        return {};
      }
    },
    /**
     * This method is the even Handler for onclick events within the emails html body
     */
    clickHref(event) {
      event.preventDefault();
      window.scrollTo(0, 0);
      this.openLink();
    },
  },
};
</script>

<style>
.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
