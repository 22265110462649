/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:e8ef617b-4ec2-4941-9606-e98f2b13f151",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_m1qWivarq",
    "aws_user_pools_web_client_id": "48ng3u3dg4o2r3m1dvp0j305vq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://q3qsmqcgi5ftnlwloh5cjafwwy.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-orc3qyq5h5hbrh5svquordhc6m",
    "aws_cloud_logic_custom": [
        {
            "name": "restBackend",
            "endpoint": "https://j2n2lq0x2m.execute-api.eu-west-3.amazonaws.com/staging",
            "region": "eu-west-3"
        }
    ],
    "aws_mobile_analytics_app_id": "9e252a7ecb63413eacd2cce9a861a54c",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
