import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { createApp } from 'vue';
import App from './App.vue';
import AmplifyVue from '@aws-amplify/ui-vue';
import mitt from 'mitt';
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";



const emitter = mitt();
Amplify.configure(awsExports);
window.$ = window.jQuery = require('jquery');
const app = createApp(App).use(store);
app.use(AmplifyVue);
app.use(Toast);
app.config.globalProperties.emitter = emitter;
app.mount('#app');
