// All selfmade querries
/**
 * Used for the history function on the profile page
 */
export const listStatisticsMessage = /* GraphQL */ `
  query ListStatistics(
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Messages {
          Subject
        }
        Completed
        CorrectlyAnswered
      }
      nextToken
      startedAt
    }
  }
`;

/**
 * Used for the Hghscoretable
 */
export const listUserScore = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Score
      }
      nextToken
      startedAt
    }
  }
`;

/**
 * Used for achievments list in profile page
 */
export const listUserAchievements = /* GraphQL */ `
  query ListAchievementsUsers(
    $filter: ModelAchievementsUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievementsUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          Name
        }
        achievements {
          Name
          Points
        }
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;

/**
 * Used to retrieve Messages
 */
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Message
        Type
        Sender
        SenderName
        Subject
        urlsID
        documentID
      }
      nextToken
      startedAt
    }
  }
`;
/**
 * Used to retrieve Documents
 */
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Content
        MetaData
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
/**
 * Used to determine the instantFail
 */
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      instantFail
    }
  }
`;
/**
 * Used to retrieve ProfilePage Data
 */
export const getUserProfile = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
    BuisnessUnit
    Name
    Mail
    Score
  }
  }
`;
/**
 * Used to retrieve ReportHints for one message
 */
export const getMessageHints = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      Hints {
        items {
          reportHints {
            Hint
          }
        }
      }
    }
  }
`;
/**
 * Used to retrieve the scenario plan which includes the current survey link
 */
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getScenario(id: $id) {
      Plan
    }
  }
`;
/**
 * Used to retrieve the statistics for the endscreen
 */
export const getFinalStats = /* GraphQL */`
  query GetFinalStats(
    $filterStatistics: ModelStatisticsFilterInput,
    $filterProgress: ModelProgressMessagesFilterInput
  ) {
    listStatistics(filter: $filterStatistics) {
    items {
      Messages {
        id
        Difficulty
        Subject
        Points
        progresss(filter: $filterProgress)  {
          items {
            id
          }
        }
      }
      CorrectlyAnswered
      Completed
    }
  }
}
`;
export const getProgress = /* GraphQL */ `
  query GetProgress($id: ID!) {
    getProgress(id: $id) {
      id
      Progress
      Goal
      Completed
    }
  }
`;
