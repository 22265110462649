<!-- The content of a screen-->
<template>
  <div
    v-show="title == store.state.selectedTitle"
    class="tab-content"
  >
    <slot />
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    setup() {
        const store = useStore()
        return{
            store,
        }
    },
}
</script>