/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      Content
      MetaData
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      Content
      MetaData
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      Content
      MetaData
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createURLS = /* GraphQL */ `
  mutation CreateURLS(
    $input: CreateURLSInput!
    $condition: ModelURLSConditionInput
  ) {
    createURLS(input: $input, condition: $condition) {
      id
      URL
      Content
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateURLS = /* GraphQL */ `
  mutation UpdateURLS(
    $input: UpdateURLSInput!
    $condition: ModelURLSConditionInput
  ) {
    updateURLS(input: $input, condition: $condition) {
      id
      URL
      Content
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteURLS = /* GraphQL */ `
  mutation DeleteURLS(
    $input: DeleteURLSInput!
    $condition: ModelURLSConditionInput
  ) {
    deleteURLS(input: $input, condition: $condition) {
      id
      URL
      Content
      Messages {
        nextToken
        startedAt
      }
      instantFail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReportHints = /* GraphQL */ `
  mutation CreateReportHints(
    $input: CreateReportHintsInput!
    $condition: ModelReportHintsConditionInput
  ) {
    createReportHints(input: $input, condition: $condition) {
      id
      Hint
      Messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReportHints = /* GraphQL */ `
  mutation UpdateReportHints(
    $input: UpdateReportHintsInput!
    $condition: ModelReportHintsConditionInput
  ) {
    updateReportHints(input: $input, condition: $condition) {
      id
      Hint
      Messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReportHints = /* GraphQL */ `
  mutation DeleteReportHints(
    $input: DeleteReportHintsInput!
    $condition: ModelReportHintsConditionInput
  ) {
    deleteReportHints(input: $input, condition: $condition) {
      id
      Hint
      Messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProgress = /* GraphQL */ `
  mutation CreateProgress(
    $input: CreateProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    createProgress(input: $input, condition: $condition) {
      id
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Progress
      Goal
      MessagesPlayed {
        nextToken
        startedAt
      }
      Completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      progressUserId
      progressScenarioId
    }
  }
`;
export const updateProgress = /* GraphQL */ `
  mutation UpdateProgress(
    $input: UpdateProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    updateProgress(input: $input, condition: $condition) {
      id
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Progress
      Goal
      MessagesPlayed {
        nextToken
        startedAt
      }
      Completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      progressUserId
      progressScenarioId
    }
  }
`;
export const deleteProgress = /* GraphQL */ `
  mutation DeleteProgress(
    $input: DeleteProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    deleteProgress(input: $input, condition: $condition) {
      id
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Progress
      Goal
      MessagesPlayed {
        nextToken
        startedAt
      }
      Completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      progressUserId
      progressScenarioId
    }
  }
`;
export const createStatistics = /* GraphQL */ `
  mutation CreateStatistics(
    $input: CreateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    createStatistics(input: $input, condition: $condition) {
      id
      Completed
      scenarios {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      CorrectlyAnswered
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statisticsScenariosId
      statisticsUserId
      statisticsMessagesId
    }
  }
`;
export const updateStatistics = /* GraphQL */ `
  mutation UpdateStatistics(
    $input: UpdateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    updateStatistics(input: $input, condition: $condition) {
      id
      Completed
      scenarios {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      CorrectlyAnswered
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statisticsScenariosId
      statisticsUserId
      statisticsMessagesId
    }
  }
`;
export const deleteStatistics = /* GraphQL */ `
  mutation DeleteStatistics(
    $input: DeleteStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    deleteStatistics(input: $input, condition: $condition) {
      id
      Completed
      scenarios {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      User {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      CorrectlyAnswered
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statisticsScenariosId
      statisticsUserId
      statisticsMessagesId
    }
  }
`;
export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      Message
      Type
      Points
      Difficulty
      Phishing
      Hints {
        nextToken
        startedAt
      }
      Sender
      SenderName
      Subject
      progresss {
        nextToken
        startedAt
      }
      urlsID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      Message
      Type
      Points
      Difficulty
      Phishing
      Hints {
        nextToken
        startedAt
      }
      Sender
      SenderName
      Subject
      progresss {
        nextToken
        startedAt
      }
      urlsID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      Message
      Type
      Points
      Difficulty
      Phishing
      Hints {
        nextToken
        startedAt
      }
      Sender
      SenderName
      Subject
      progresss {
        nextToken
        startedAt
      }
      urlsID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createScenario = /* GraphQL */ `
  mutation CreateScenario(
    $input: CreateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    createScenario(input: $input, condition: $condition) {
      id
      Name
      Description
      Image
      Plan
      BuisnessUnits {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateScenario = /* GraphQL */ `
  mutation UpdateScenario(
    $input: UpdateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    updateScenario(input: $input, condition: $condition) {
      id
      Name
      Description
      Image
      Plan
      BuisnessUnits {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteScenario = /* GraphQL */ `
  mutation DeleteScenario(
    $input: DeleteScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    deleteScenario(input: $input, condition: $condition) {
      id
      Name
      Description
      Image
      Plan
      BuisnessUnits {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAchievements = /* GraphQL */ `
  mutation CreateAchievements(
    $input: CreateAchievementsInput!
    $condition: ModelAchievementsConditionInput
  ) {
    createAchievements(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAchievements = /* GraphQL */ `
  mutation UpdateAchievements(
    $input: UpdateAchievementsInput!
    $condition: ModelAchievementsConditionInput
  ) {
    updateAchievements(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAchievements = /* GraphQL */ `
  mutation DeleteAchievements(
    $input: DeleteAchievementsInput!
    $condition: ModelAchievementsConditionInput
  ) {
    deleteAchievements(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Points
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBuisnessUnit = /* GraphQL */ `
  mutation CreateBuisnessUnit(
    $input: CreateBuisnessUnitInput!
    $condition: ModelBuisnessUnitConditionInput
  ) {
    createBuisnessUnit(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Scenarios {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBuisnessUnit = /* GraphQL */ `
  mutation UpdateBuisnessUnit(
    $input: UpdateBuisnessUnitInput!
    $condition: ModelBuisnessUnitConditionInput
  ) {
    updateBuisnessUnit(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Scenarios {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBuisnessUnit = /* GraphQL */ `
  mutation DeleteBuisnessUnit(
    $input: DeleteBuisnessUnitInput!
    $condition: ModelBuisnessUnitConditionInput
  ) {
    deleteBuisnessUnit(input: $input, condition: $condition) {
      id
      Name
      Users {
        nextToken
        startedAt
      }
      Scenarios {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      Name
      Mail
      Score
      Achievements {
        nextToken
        startedAt
      }
      BuisnessUnit
      Phished
      CognitoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      Name
      Mail
      Score
      Achievements {
        nextToken
        startedAt
      }
      BuisnessUnit
      Phished
      CognitoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      Name
      Mail
      Score
      Achievements {
        nextToken
        startedAt
      }
      BuisnessUnit
      Phished
      CognitoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessagesReportHints = /* GraphQL */ `
  mutation CreateMessagesReportHints(
    $input: CreateMessagesReportHintsInput!
    $condition: ModelMessagesReportHintsConditionInput
  ) {
    createMessagesReportHints(input: $input, condition: $condition) {
      id
      reportHintsID
      messagesID
      reportHints {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessagesReportHints = /* GraphQL */ `
  mutation UpdateMessagesReportHints(
    $input: UpdateMessagesReportHintsInput!
    $condition: ModelMessagesReportHintsConditionInput
  ) {
    updateMessagesReportHints(input: $input, condition: $condition) {
      id
      reportHintsID
      messagesID
      reportHints {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessagesReportHints = /* GraphQL */ `
  mutation DeleteMessagesReportHints(
    $input: DeleteMessagesReportHintsInput!
    $condition: ModelMessagesReportHintsConditionInput
  ) {
    deleteMessagesReportHints(input: $input, condition: $condition) {
      id
      reportHintsID
      messagesID
      reportHints {
        id
        Hint
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProgressMessages = /* GraphQL */ `
  mutation CreateProgressMessages(
    $input: CreateProgressMessagesInput!
    $condition: ModelProgressMessagesConditionInput
  ) {
    createProgressMessages(input: $input, condition: $condition) {
      id
      progressID
      messagesID
      progress {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProgressMessages = /* GraphQL */ `
  mutation UpdateProgressMessages(
    $input: UpdateProgressMessagesInput!
    $condition: ModelProgressMessagesConditionInput
  ) {
    updateProgressMessages(input: $input, condition: $condition) {
      id
      progressID
      messagesID
      progress {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProgressMessages = /* GraphQL */ `
  mutation DeleteProgressMessages(
    $input: DeleteProgressMessagesInput!
    $condition: ModelProgressMessagesConditionInput
  ) {
    deleteProgressMessages(input: $input, condition: $condition) {
      id
      progressID
      messagesID
      progress {
        id
        Progress
        Goal
        Completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        progressUserId
        progressScenarioId
      }
      messages {
        id
        Message
        Type
        Points
        Difficulty
        Phishing
        Sender
        SenderName
        Subject
        urlsID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createScenarioBuisnessUnit = /* GraphQL */ `
  mutation CreateScenarioBuisnessUnit(
    $input: CreateScenarioBuisnessUnitInput!
    $condition: ModelScenarioBuisnessUnitConditionInput
  ) {
    createScenarioBuisnessUnit(input: $input, condition: $condition) {
      id
      scenarioID
      buisnessUnitID
      scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buisnessUnit {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateScenarioBuisnessUnit = /* GraphQL */ `
  mutation UpdateScenarioBuisnessUnit(
    $input: UpdateScenarioBuisnessUnitInput!
    $condition: ModelScenarioBuisnessUnitConditionInput
  ) {
    updateScenarioBuisnessUnit(input: $input, condition: $condition) {
      id
      scenarioID
      buisnessUnitID
      scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buisnessUnit {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteScenarioBuisnessUnit = /* GraphQL */ `
  mutation DeleteScenarioBuisnessUnit(
    $input: DeleteScenarioBuisnessUnitInput!
    $condition: ModelScenarioBuisnessUnitConditionInput
  ) {
    deleteScenarioBuisnessUnit(input: $input, condition: $condition) {
      id
      scenarioID
      buisnessUnitID
      scenario {
        id
        Name
        Description
        Image
        Plan
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buisnessUnit {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAchievementsUser = /* GraphQL */ `
  mutation CreateAchievementsUser(
    $input: CreateAchievementsUserInput!
    $condition: ModelAchievementsUserConditionInput
  ) {
    createAchievementsUser(input: $input, condition: $condition) {
      id
      achievementsID
      userID
      achievements {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAchievementsUser = /* GraphQL */ `
  mutation UpdateAchievementsUser(
    $input: UpdateAchievementsUserInput!
    $condition: ModelAchievementsUserConditionInput
  ) {
    updateAchievementsUser(input: $input, condition: $condition) {
      id
      achievementsID
      userID
      achievements {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAchievementsUser = /* GraphQL */ `
  mutation DeleteAchievementsUser(
    $input: DeleteAchievementsUserInput!
    $condition: ModelAchievementsUserConditionInput
  ) {
    deleteAchievementsUser(input: $input, condition: $condition) {
      id
      achievementsID
      userID
      achievements {
        id
        Name
        Points
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        Name
        Mail
        Score
        BuisnessUnit
        Phished
        CognitoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
