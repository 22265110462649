<template>
  <template v-if="!store.getters.locked">
    <div id="profileTable">
      <table id="profile">
        <tr>
          <td class="label">
            Name:
          </td>
          <td class="data">
            {{ profile.Name }}
          </td>
        </tr>
        <tr>
          <td class="label">
            Mail:
          </td>
          <td class="data">
            {{ profile.Mail }}
          </td>
        </tr>
        <tr>
          <td class="label">
            Score:
          </td>
          <td class="data">
            {{ profile.Score }}
          </td>
        </tr>
        <tr>
          <td class="label">
            BuisnessUnit:
          </td>
          <td class="data">
            {{ profile.BuisnessUnit }}
          </td>
        </tr>
      </table>
    </div>
  </template>
  <template v-else>
    <!-- Spinner from https://dribbble.com/shots/2882839-Material-Spinner-Principle-File-Attached -->
    <img
      id="loading-spinner"
      src="/spinner-loop.gif"
      alt="Loading spinner"
    >
  </template>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { getBuisnessUnit } from "@/graphql/queries";
import { getUserProfile } from "@/graphql/customQueries";
import { useStore } from "vuex";
export default {
  setup(props) {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      profile: [],
      user: JSON.parse(this.$store.state.user),
    };
  },
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  watch: {
    locked(val) {
      if (!val) {
        this.user = JSON.parse(this.$store.state.user);
        this.fetchUser();
      }
    },
  },
  mounted() {
    if (!this.$store.getters.locked) this.fetchUser();
  },
  methods: {
    fetchUser() {
      try {
        API.graphql(
          graphqlOperation(getUserProfile, {
            id: this.user.id,
          })
        ).then((result) => {
          this.profile = result.data?.getUser;
          API.graphql(
            graphqlOperation(getBuisnessUnit, {
              id: result.data?.getUser?.BuisnessUnit,
            })
          ).then((result) => {
            this.profile.BuisnessUnit = result.data?.getBuisnessUnit.Name;
          });
        });
      } catch (fetchUserError) {
        console.error({ fetchUserError });
      }
    },
  },
};
</script>
<style scoped>
#profileTable {
  padding-left: 10px;
  padding-right: 10px;
}
#profile {
  font-size: 2vw;
  width: 100%;
}
#profile-data {
  font-size: 2vw;
}
.label {
  text-align: left;
}
.data {
  text-align: right;
}
</style>
