<template>
  <div id="historyTable">
    <table>
      <thead>
        <tr>
          <th>Message</th>
          <th>Identified</th>
          <th>Played</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="message in history"
          :key="message.name"
        >
          <td class="name">
            {{ message.name }}
          </td>
          <td>
            {{ message.identified }}
          </td>
          <td>
            {{ message.date.toDateString() }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { useStore } from "vuex";
import { listStatisticsMessage } from "@/graphql/customQueries";
export default {
  setup(props) {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      history: [],
      user: JSON.parse(this.$store.state.user),
    };
  },
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  watch: {
    locked(val) {
      if (!val) {
        this.user = JSON.parse(this.$store.state.user);
        this.fetchHistory();
      }
    },
  },
  mounted() {
    if (!this.$store.getters.locked) this.fetchHistory();
  },
  methods: {
    fetchHistory() {
      try {
        API.graphql(
          graphqlOperation(listStatisticsMessage, {
            filter: { statisticsUserId: { eq: this.user.id } },
          })
        ).then((result) => {
          let ids = result.data?.listStatistics?.items;
          this.history = ids.map((x) => ({
            name: x.Messages.Subject,
            identified: x.CorrectlyAnswered,
            date: new Date(x.Completed),
          }));
        });
      } catch (fetchHistoryError) {
        console.error({ fetchHistoryError });
      }
    },
  },
};
</script>
<style scoped>
#historyTable {
  overflow-y: auto;
  height: 90%;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}
#historyTable thead th {
  position: sticky;
  top: 0;
  background: #eee;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}
.score {
  text-align: right;
}
.name {
  text-align: left;
}
.currentUser {
  background: rgb(101, 187, 228);
}
</style>
