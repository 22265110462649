<!--
  This component represents the Account/Profile Page. All the Logic is outsourced into subcomponents
-->
<template>
  <div id="accountPage">
    <div id="account">
      <div id="userData">
        <h2>Profile</h2>
        <profile-table />
        <b>Logout:</b><br>
        <logout
          id="icon-logout"
          @click="signOut"
        />
      </div>
      <div id="achievments">
        <h2>Achievements</h2>
        <achievements-table />
      </div>
      <div id="history">
        <h2>History</h2>
        <history-table />
      </div>
      <div id="highscore">
        <h2>Highscore</h2>
        <highscore-table />
      </div>
    </div>
  </div>
</template>


<script setup>
  import HighscoreTable from './HighscoreTable.vue'
  import AchievementsTable from './AchievementsTable.vue'
  import HistoryTable from './HistoryTable.vue'
  import ProfileTable from './ProfileTable.vue'
  import {useStore} from "vuex";
  const store = useStore()
  import {Logout} from 'mdue'
  import { Auth } from 'aws-amplify';

/**
 * This will sign out the user from all instances
 */
  async function signOut() {
      try {
          // removes all the local storage cached items 
          store.commit('login')
          // signs out from Amazon Cognito
          await Auth.signOut({ global: true });
      } catch (error) {
          console.error('error signing out: ', error);
      }
  }
</script>

<style>
#icon-logout{
  font-size: 32px;
  border-radius: 16px;
  padding: 4px;
  background-color: #ccc;
}
#account{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  margin-left: auto;
  margin-right:auto;
  justify-content: center;
}
@media (max-width: 800px){
  #account{
    grid-template-columns: 1fr;
  }
}
#userData{
  background-color: white;
  border: 2px solid rgba(199, 199, 199, 0.863);
}
#achievments{
  background-color: white;
  border: 2px solid rgba(199, 199, 199, 0.863);
}
#history{
  background-color: white;
  border: 2px solid rgba(199, 199, 199, 0.863);
}
#highscore{
  background-color: white;
  border: 2px solid rgba(199, 199, 199, 0.863);
}
</style>