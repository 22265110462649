<template>
  <div id="endscreen">
    <h1>You've made it!</h1>
    <h2>
      You've identified {{ identified }} out of {{ items.length }} mails
      correctly
    </h2>
    <p>
      Congratulations, and thanks for playing! You've made it through this
      scenario. Below you can see how you've performed and how your colleagues
      did. But don't worry, only you can see your real name in the list, and all
      other names are randomized. If you want to, you can repeat this scenario
      with a different set of emails. <br>
      But for now, I would be very grateful if you could answer this survey (
      <a :href="survey">{{ survey }}</a>) so I could use it for my Master's thesis. All answers will be
      anonymized. It is no phishing link, I promise. <br><br>
      If you have any questions or suggestions that don't fit into the
      questionnaire, feel free to contact me either via slack @Thorsten Rösler
      or via mail
      <a href="mailto:thorsten.roesler@teaminternet.com">thorsten.roesler@teaminternet.com</a>
    </p>
    <div id="finalStatsTable">
      <table>
        <thead>
          <tr>
            <th>Message</th>
            <th>Identified</th>
            <th>Difficulty</th>
            <th>Points</th>
            <th>Played</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="message in items"
            :key="message.name"
          >
            <td class="name">
              {{ message.name }}
            </td>
            <td>
              <checkbox-marked-circle-outline v-if="message.identified" />
              <close-circle-outline v-else />
            </td>
            <td>
              {{ message.difficulty }}
            </td>
            <td>
              {{ message.points }}
            </td>
            <td>
              {{ message.date.toDateString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <highscore-table />
  </div>
</template>
<script setup>
import { API, graphqlOperation } from "aws-amplify";
import { getFinalStats, getSurvey } from "../graphql/customQueries";
import { useStore } from "vuex";
import { CheckboxMarkedCircleOutline, CloseCircleOutline } from "mdue";
import HighscoreTable from "./HighscoreTable.vue";
const store = useStore();
</script>
<script>
export default {
  data() {
    return {
      items: [],
      survey: "",
      identified: 0,
    };
  },
  mounted() {
    this.fetchStats();
    this.getSurveyLink();
  },
  methods: {
    /**
     * This method fetches all statistics for the current user/scenario combination and filters out those that correspond to the current run
     */
    fetchStats() {
      try {
        API.graphql(
          graphqlOperation(getFinalStats, {
            filterProgress: {
              progressID: { eq: this.$store.state.progressID },
            },
            filterStatistics: {
              and: {
                statisticsUserId: { eq: JSON.parse(this.$store.state.user).id },
                statisticsScenariosId: { eq: this.$store.state.scenario },
              },
            },
          })
        ).then((result) => {
          var stats = result.data?.listStatistics?.items;
          this.items = stats.reduce(function (res, stat) {
            if (stat.Messages.progresss.items.length !== 0)
              res.push({
                name: stat.Messages.Subject,
                points: stat.Messages.Points,
                difficulty: stat.Messages.Difficulty,
                identified: stat.CorrectlyAnswered,
                date: new Date(stat.Completed),
              });
            return res;
          }, []);
          this.identified = this.items.reduce(function (res, stat) {
            if (stat.identified) return ++res;
            return res;
          }, 0);
        });
      } catch (fetchStatsError) {
        console.error({ fetchStatsError });
      }
    },
    /**
     * This method extracts the questionair link from the scenario plan
     */
    getSurveyLink() {
      try {
        API.graphql(
          graphqlOperation(getSurvey, {
            id: this.$store.state.scenario,
          })
        ).then((result) => {
          this.survey = JSON.parse(result.data?.getScenario?.Plan).feedbackForm;
        });
      } catch (getSurveyError) {
        console.error({ getSurveyError });
      }
    },
  },
};
</script>

<style>
#endscreen {
  padding-right: 10px;
  padding-left: 10px;
}
#finalStatsTable {
  overflow-y: auto;
  height: 40vh;
  background-color: white;
  margin-bottom: 10px;
}
#finalStatsTable thead th {
  position: sticky;
  top: 0;
  background: #90bc15;
}
#endscreen #highscoreTable thead th {
  background: #90bc15;
}
#endscreen #highscoreTable {
  padding-left: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}
.score {
  text-align: right;
}
.name {
  text-align: left;
}
</style>
