<!--
  This component renders a table with all achievments the user achieved.
-->
<template>
  <div id="achievementsTable">
    <table>
      <thead>
        <tr>
          <th>Achievement</th>
          <th>Points</th>
          <th>Earend</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="achievement in earnedAchievements"
          :key="achievement.name"
        >
          <td class="name">
            {{ achievement.name }}
          </td>
          <td class="score">
            {{ achievement.score }}
          </td>
          <td>
            {{ achievement.date.toDateString() }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { listUserAchievements } from "@/graphql/customQueries";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      earnedAchievements: [],
      user: JSON.parse(this.$store.state.user),
    };
  },
  // this semaphore has to be computed so watch works
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  // Reloads the User object and only fetches achievements when unlocked
  watch: {
    locked(val) {
      if (!val) {
        this.user = JSON.parse(this.$store.state.user);
        this.fetchAchievements();
      }
    },
  },
  mounted() {
    if (!this.$store.getters.locked) this.fetchAchievements();
  },
  methods: {
    // fetches all achievements for the current user and maps them into a parsable array
    fetchAchievements() {
      try {
        API.graphql(
          graphqlOperation(listUserAchievements, {
            filter: { userID: { eq: this.user.id } },
          })
        ).then((result) => {
          let res = result.data?.listAchievementsUsers?.items;
          this.earnedAchievements = res
            .map((x) => ({
              name: x.achievements.Name,
              score: x.achievements.Points,
              date: new Date(x.createdAt),
            }))
            .sort(function (a, b) {
              return b.date - a.date;
            });
        });
      } catch (fetchAchievementsError) {
        console.error({ fetchAchievementsError });
      }
    },
  },
};
</script>
<style scoped>
#achievementsTable {
  overflow: auto;
  height: 90%;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}
#achievementsTable thead th {
  position: sticky;
  top: 0;
  background: #eee;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}
.score {
  text-align: right;
}
.name {
  text-align: left;
}
.currentUser {
  background: rgb(101, 187, 228);
}
</style>
