<template>
  <div class="avatarCard">
    <img :src="'/avatars/'+image">
    <p id="name">
      {{ name }}
    </p>
    <p id="position">
      {{ position }}
    </p>
  </div>
</template>
<script>

export default {
    props: {
        image: {
            type: String,
            required: true,
            default: "avatar-you.jpg"
            },
        name: {
            type: String,
            required: true,
            default: "You"
            },
        position: {
            type: String,
            required: true,
            default: "First Level Support"
            },
    }
}
</script>
<style scoped>
    .avatarCard{
        border: 2px solid black;
        width: 400px;
    }
    .avatarCard img{
        width: 100%;
        height: 400px;
        object-fit: contain;
    }
    #name{
        font-size: 16pt;
        font-weight: bolder;
    }
</style>