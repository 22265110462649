<template>
  <template v-if="!store.getters.locked">
    <header>
      <div id="mail-data">
        <div id="sender">
          From: <span :title="mailData.sender">{{ mailData.senderName }}</span>
        </div>
        <div id="subject">
          Subject: {{ mailData.subject }}
        </div>
        <div id="receiver">
          To: {{ mailData.receiverName }}
          <span style="color: grey">&lt;{{ mailData.receiver }}&gt;</span>
        </div>
        <div
          v-if="attachment != null"
          id="attachment"
          style="cursor: pointer"
          @click="openAttachment"
        >
          <attachment id="docIcon" />
          <span id="docName">{{ JSON.parse(attachment.MetaData).name }}</span>
          <span
            id="docSize"
            style="color: grey"
          >{{
            JSON.parse(attachment.MetaData).size
          }}</span>
        </div>
      </div>
      <div id="action-buttons">
        {{ mailData.date }}
        <div id="spamButton">
          <span title="Report Phishing Mail"><archive-alert
            id="reportSpam"
            style="cursor: pointer"
            @click="submitMail(true)"
          /></span>
        </div>
        <div id="archiveButton">
          <span title="Mark Mail as read"><email-mark-as-unread
            id="archiveMail"
            style="cursor: pointer"
            @click="submitMail(false)"
          /></span>
        </div>
      </div>
    </header>
    <div id="content">
      <mail-content />
    </div>
  </template>
  <template v-else>
    <!-- Spinner from https://dribbble.com/shots/2882839-Material-Spinner-Principle-File-Attached -->
    <img
      id="loading-spinner"
      src="/spinner-loop.gif"
      alt="Loading spinner"
    >
  </template>
</template>
<script setup>
import MailContent from "./MailContent.vue";
import { API, graphqlOperation } from "aws-amplify";
import { listDocuments, getDocument } from "../graphql/customQueries";
import { ArchiveAlert, EmailMarkAsUnread, Attachment } from "mdue";
import { useStore } from "vuex";
const store = useStore();
</script>

<script>
export default {
  components: { MailContent, ArchiveAlert, EmailMarkAsUnread, Attachment },
  data() {
    return {
      mailData: {},
      attachment: null,
    };
  },
  computed: {
    locked() {
      return this.$store.getters.locked;
    },
  },
  watch: {
    locked(val) {
      if (!val) {
        this.init();
      }
    },
  },
  async created() {
    if (!this.$store.getters.locked) this.init();
  },
  mounted() {
    this.emitter.on("nextMail", (message) => {
      this.attachment = null;
      this.retrieveMail(message).then((res) => {
        this.$store.commit("nextMessage", JSON.stringify(res));
        this.mailData = res;
        this.emitter.emit("newContent", res);
      });
    });
  },
  methods: {
    submitMail(phish, instant = false) {
      if (
        instant ||
        confirm(
          `Do you really want to ${
            phish ? "report this mail as phishing" : "archive this mail"
          }?`
        )
      ) {
        this.emitter.emit("submitMail", phish);
        // eslint-disable-next-line
        $("#report").show();
        // eslint-disable-next-line
        $("#background").show();
        // disable scrolling when in overlay
        $("body").css("overflow", "hidden");
      }
    },
    /**
     * This method will retrieve the attachment if present and reformat the mail data
     */
    async retrieveMail(message) {
      let user = JSON.parse(this.$store.state.user);
      //check whether there is an attchment
      if (message.documentID) {
        this.retrieveAttachment(message.documentID).then((res) => {
          this.$store.commit("nextAttachment", JSON.stringify(res));
          this.attachment = res;
        });
      }
      // this is used to generate a date and format it
      let temp = new Date();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return {
        sender: message.Sender,
        senderName: message.SenderName,
        subject: message.Subject,
        receiver: user.Mail,
        receiverName: user.Name,
        attachment: message.documentID || "",
        date: `${("0" + temp.getDate()).slice(-2)}.${
          months[temp.getMonth()]
        } at ${("0" + temp.getHours()).slice(-2)}:${(
          "0" + temp.getMinutes()
        ).slice(-2)}`,
        body: message.Message,
        urlId: message.urlsID,
        messageId: message.id,
      };
    },
    /**
     * Here the attachment is retrieved from the API
     */
    async retrieveAttachment(attachmentId) {
      try {
        let res = await API.graphql(
          graphqlOperation(listDocuments, {
            filter: { id: { eq: attachmentId } },
          })
        );
        return res?.data?.listDocuments?.items[0];
      } catch (retrieveAttachmentError) {
        console.error({ retrieveAttachmentError });
        return {};
      }
    },
    /**
     *  switches to Document viewer and emits data
     */
    openAttachment() {
      this.checkAttachment().then((res) => {
        if (res) {
          this.emitter.emit("open-attachment", this.attachment);
          this.$store.commit("selectTitle", "Preview");
        } else {
          this.submitMail(false, true);
        }
      });
    },
    async checkAttachment() {
      try {
        let ret = await API.graphql(
          graphqlOperation(getDocument, {
            id: this.attachment.id,
          })
        );
        return !ret.data.getDocument.instantFail;
      } catch (checkAttachmentError) {
        console.error({ checkAttachmentError });
        return true;
      }
    },
    async init() {
      // check whether the current message is cached
      if (!localStorage.getItem("currentMessage")) {
        // get currentMessage from server
        try {
          // call the API and ask whether the mail was correctly identified
          const response = await API.post("restBackend", "/check", {
            body: {
              login: true,
              userID: JSON.parse(this.$store.state.user)?.id,
              scenarioID: this.$store.state.scenario,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          let message = response.message;
          this.$store.commit("currentProgress", response.progress);
          // parse the message retrieve the attachment and trigger rerendering
          this.retrieveMail(message).then((res) => {
            this.$store.commit("nextMessage", JSON.stringify(res));
            this.mailData = res;
            this.emitter.emit("newContent", res);
          });
        } catch (mailWindowCreationError) {
          console.error({ mailWindowCreationError });
        }
      } else {
        this.mailData = JSON.parse(this.$store.getters.currentMessage);
        this.attachment = JSON.parse(this.$store.getters.attachment);
        this.emitter.emit("newContent", this.mailData);
      }
    },
  },
};
</script>
<style>
header {
  float: none;
  width: 100%;
  height: 130px;
  box-shadow: 0px 24px 3px -24px grey;
}
#mail-data {
  float: left;
  margin-left: 24px;
  text-align: left;
}
#action-buttons {
  margin-right: 24px;
}
#action-buttons {
  float: right;
  position: relative;
  height: 100%;
}
#sender {
  font-weight: bold;
}
#reportSpam {
  font-size: 27pt;
}
#archiveMail {
  font-size: 25pt;
}
#spamButton {
  position: absolute;
  margin-top: 13px;
}
#archiveButton {
  position: absolute;
  margin-left: 50px;
  margin-top: 13px;
}
#content {
  margin: 10px 24px 0px 24px;
}
#attachment {
  border: 1px solid black;
  display: inline-grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
  row-gap: 1px;
  padding: 0 2px;
}
#docIcon {
  grid-column: 1;
  grid-row: 1 / 3;
  font-size: 22pt;
  margin-top: 25%;
}
#docName {
  grid-column: 2;
  grid-row: 1;
}
#docSize {
  grid-column: 2;
  grid-row: 2;
}
</style>
