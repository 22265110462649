<template>
  <div id="evaluationWindow">
    <div id="report">
      <template v-if="!loading">
        <h1
          v-if="success"
          style="color: green"
        >
          You've identified this mail correctly it was
          {{ reportData ? "a phishing mail" : "no phishing mail" }}.
        </h1>
        <h1
          v-else
          style="color: red"
        >
          {{
            reportData
              ? "You've reported this mail as phishing but it actually was a legitimate mail"
              : "You haven't identified this mail as a phish"
          }}.
        </h1>
        <img
          id="mark"
          src="/avatars/avatar-mark.jpg"
        >
        <p>
          Hey {{ JSON.parse(store.state.user).Name }}, it's Mark from IT. Here
          are a few hints how this mail could have been identified:
        </p>
        <ul>
          <li
            v-for="item in items"
            :key="item.reportHints"
          >
            <p>
              {{ item.reportHints.Hint }}
            </p>
          </li>
        </ul>
        <button
          id="hide"
          @click="hide()"
        >
          Next Mail
        </button>
      </template>
      <template v-else>
        <!-- Spinner from https://dribbble.com/shots/2882839-Material-Spinner-Principle-File-Attached -->
        <img
          id="loading-spinner"
          src="/spinner-loop.gif"
          alt="Loading spinner"
        >
      </template>
    </div>
    <div id="background" />
  </div>
</template>
<script setup>
import { API, graphqlOperation } from "aws-amplify";
import { getMessageHints } from "../graphql/customQueries";
import { useStore } from "vuex";
const store = useStore();
</script>
<script>
export default {
  data() {
    return {
      items: [],
      reportData: false,
      success: false,
      loading: true,
      completed: false,
    };
  },
  mounted() {
    this.emitter.on("submitMail", (reportData) => {
      this.loading = true;
      this.reportData = reportData;
      let mailData = JSON.parse(this.$store.getters.currentMessage);
      try {
        API.graphql(
          graphqlOperation(getMessageHints, { id: mailData.messageId })
        ).then((result) => {
          this.items = result.data?.getMessages?.Hints?.items;
          this.check().then((res) => {
            this.success = res.success;
            this.loading = false;
            this.$store.commit("currentProgress", res.progress);
          });
        });
      } catch (submitMailError) {
        console.error({ submitMailError });
      }
    });
  },
  methods: {
    hide() {
      // eslint-disable-next-line
      $("#report").hide();
      // eslint-disable-next-line
      $("#background").hide();
      //reenabe scrolling
      $("body").css("overflow", "auto");
      // add functionality for checking number of recognised messages
      this.emitter.emit(
        "achievement",
        this.reportData && this.success
          ? "0171a144-b461-4e5e-b00a-20eb84d159fd"
          : !this.reportData && !this.success
          ? "7343b1ab-d0bc-49d0-8fc6-f30cb616c742"
          : false
      );
      if (this.completed) {
        this.$store.commit("end", true);
      }
    },
    async check() {
      try {
        // call the API and ask whether the mail was correctly identified
        const response = await API.post("restBackend", "/check", {
          body: {
            phish: this.reportData,
            userID: JSON.parse(this.$store.state.user)?.id,
            scenarioID: this.$store.state.scenario,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (!response.completed) {
          // trigger next mail
          this.emitter.emit("nextMail", response.message);
        }
        this.completed = response.completed;
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
#report {
  width: 50vw;
  height: 50vh;
  background-color: cadetblue;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow-y: hidden;
  text-align: left;
  display: none;
  border-radius: 5%;
  padding: 10px;
  overflow-y: auto;
}
#background {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  background-attachment: fixed;
  filter: blur(8px);
  display: none;
}
#hide {
  margin: 0 auto;
  display: block;
}
#loading-spinner {
  width: 100%;
}
#mark {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  float: left;
  margin-right: 10px;
}
</style>
