<template>    
  <div id="urlBar">
    {{ link.URL }}
  </div>
  <div
    id="BrowserContent"
    v-html="link.Content"
  />
</template>


<script>
export default{
    data() {
    return {
      link: {
        Content: "<h1> Just a demo</h1>",
        URL: "http://www.iwanttobreakfree.de",
        id: "f4e0e424-1649-426d-98cf-750ea64b868e"
      }
    };
  },
  mounted() { 
    this.emitter.on("open-link", link => {
      this.link = link;
    });
  }
};
</script>

<style scoped>
#urlBar{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  border:2px solid black;
  background-color: white;
  }
</style>